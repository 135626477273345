<template>
  <div>
    <div class="flex mb-2.5">
      <p class="font-barlow font-medium text-xl text-grey-medium-2">
        {{ title }} {{ displayVersionForSeller(productVersion) }} /<span
          class="font-barlow font-bold text-xl text-grey-dark-2 ml-1"
        >
          Communication
        </span>
      </p>

      <div class="flex flex-grow justify-end">
        <Button
          text="Back"
          type="primary-white"
          size="medium"
          :iconLeft="arrowLeft"
          :rotateArrow="true"
          :onClick="goBack"
          class="mr-2"
          data-testid="back-button"
        />
        <Button
          text="Next"
          type="primary"
          :iconRight="arrowRight"
          :disabled="errorWatch"
          size="medium"
          :onClick="goNext"
          data-testid="next-button"
        />
      </div>
    </div>
    <InfoBanner />
    <CommunicationBanner />
    <div class="bg-white shadow rounded px-2 pt-3 mt-2 pb-4.5 relative">
      <div class="max-w-4/5">
        <p class="font-barlow font-bold text-mdl text-grey-light mb-2">
          Email Notifications for Participants
        </p>
        <Button
            v-if="this.isSeller"
            text="Activity log"
            type="secondary"
            size="medium"
            class="absolute top-4 right-2"
            @click.native="() => goToActivity()"
            data-testid="cancel-button"
          />
        <p class="text-mdh text-grey-dark-1 mt-4">
          Make sure participants are aware of the assessment context and
          timeline, by using the recommended email messages below. If needed,
          new messages can also be created.
        </p>
          <div v-if="mappedList" class="divide-y divide-grey-light-1">
            <div v-for="(item, index) in mappedList" :key="item.id" class="flex-1">
              <Email
                :index="index"
                :save="save"
                :deleteEmail="deleteEmail"
                :ref="`email-${index}`"
              />
            </div>
            <div
              v-for="(item, index) in newMessages"
              :key="`new-message-${item.key}`"
              :ref="`email-${messages.length + index}`"
              class="flex-1"
            >
              <NewEmail
                :index="index"
                :newMessage="item"
                :save="saveNewMessage"
                :cancel="cancelCreation"
              />
            </div>
            <div class="flex justify-end">
              <Button
                text="New Message"
                type="secondary"
                size="medium"
                :iconLeft="plus"
                :onClick="create"
                data-testid="new-message-button"
                class="mt-3"
              />
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import store from '@/store';
import moment from "moment";
import RTF from '../../../components/RichText/TipTap.vue';
import Button from '../../../components/Button/Button.vue';
import InputField from '../../../components/InputField/InputField.vue';
import NewSelect from '../../../components/Select/NewSelect.vue';
import plus from '@/assets/img/icons/add-blue.svg';
import arrowRight from '@/assets/img/icons/arrow-right-white.svg';
import arrowLeft from '@/assets/img/icons/arrow-right.svg';
import Email from '../components/Email.vue';
import CommunicationBanner from '../components/CommunicationBanner.vue';
import NewEmail from '../components/NewEmail.vue';
import InfoBanner from '../components/InfoBanner.vue';

export default {
  name: 'Communication',
  components: {
    Button,
    InputField,
    NewSelect,
    RTF,
    Email,
    CommunicationBanner,
    NewEmail,
    InfoBanner,
  },
  data: () => ({
    mappedList: null,
    plus,
    arrowRight,
    arrowLeft,
    newMessages: [],
    newMessage: {
      from: '',
      subject: '',
      message: '',
      messageAudience: 0,
      messageFrequency: 1,
      surveyMessageType: 2,
      messageName: '',
      sendDate: moment((new Date(Date.now()).setHours(6, 0, 0))).format("YYYY-MM-DDTHH:mm:ss"),
    },
  }),
  computed: {
    ...mapState({
      title: (state) => state.assessmentSetup.assessmentTitle,
      id: (state) => state.assessmentSetup.cloneId,
      messages: (state) => state.assessmentSetup.messages,
      generalInfo: (state) => state.assessmentSetup.generalInfo,
      assessmentSetup: (state) => state.assessmentSetup,
    }),
    ...mapGetters({ 
      checkChanges: 'assessmentSetup/getDirtyCommunication',
      isImpersonating: "auth/isImpersonating",
      isSeller: "auth/isSeller",
    }),
    errorWatch() {
      const totalLength = this.messages.length + this.newMessages.length;
      for (let i = 0; i < totalLength; i++) {
        const email = this.$refs[`email-${i}`];
        if (email && email[0]?.$v?.$invalid) return true;
      }
      return false;
    },
    productVersion(){
      return this.generalInfo.productVersion;
    },
  },
  async mounted() {
    try {
      await this.getMessages({ id: this.id });
    }
    catch(err) {
      if(!this.messages || !this.messages.length) {
        this.showError({
          messageHeader: "Error",
          message: err.message || "Error fetching messages. Please try again in a few moments",
        });
      }
    }
    this.mappedList = [...this.messages];
    this.newMessage.from = this.mappedList[0].from;
    this.messages.forEach(message => {
      if (!message.timezone) message.timezone = { label: "(UTC) Coordinated Universal Time", id: "UTC" };
    });
  },
  watch: {
    messages() {
      this.messages.forEach(message => {
        if (!message.timezone) message.timezone = { label: "(UTC) Coordinated Universal Time", id: "UTC" };
      });
      this.mappedList = [...this.messages];
    }
  },
  methods: {
    ...mapActions({
      addNewMessage: 'assessmentSetup/addNewMessage',
      getMessages: 'assessmentSetup/getMessages',
      saveMessages: 'assessmentSetup/saveMessages',
      deleteCustomMessage: 'assessmentSetup/deleteCustomMessage',
      setMessages: 'assessmentSetup/setMessages',
      openModal: 'modals/openModal',
      showError: "alerts/showError",
    }),
    async goNext() {
      try {
        await this.saveChanges(true, true);
        this.$router.push('/assessments/summary');
      }
      catch {}
    },
    goToActivity() {
      this.$router.push('/assessments/communication/activity');
    },
    async saveChanges(preSaveAll = false, updateStep = false, isCreateNew = false) {
      if (preSaveAll) {
        for (let i = 0; i < this.mappedList.length; i++) {
          this.save(this.$refs[`email-${i}`][0].message, i, false);
        }
      }
      try {
        await this.saveMessages({
          id: this.id,
          messages: this.mappedList,
          updateStep,
          surveyType: this.generalInfo?.surveyType,
        });
      }
      catch(err) {
        this.showError({
          messageHeader: "Error",
          message: err.message || `Error ${isCreateNew ? 'creating new message' : 'updating messages'}. Please try again in a few moments`,
        });
        throw err;
      }
    },
    goBack() {
      this.$router.push('/assessments/settings');
    },
    save(message, index, doBESave = true) {
      this.mappedList[index] = message;
      if (doBESave) this.saveChanges();
    },
    create() {
      this.newMessages.push({ ...this.newMessage, key: new Date().valueOf().toString() });
    },
    async deleteEmail(index) {
      try {
        const messageId = this.mappedList[index].id;
        await this.deleteCustomMessage({ messageId });
        this.mappedList = [...this.messages]
      } catch (err) {
        this.showError({
          messageHeader: "Error",
          message: err.message || "Error deleating message. Please try again in a few moments",
        });
      } 
    },
    async saveNewMessage(message, index) {
      this.mappedList[this.mappedList.length] = message;
      await this.saveChanges(false, false, true)
      this.cancelCreation(index);
      await this.getMessages({ id: this.id });
    },
    cancelCreation(index) {
      this.newMessages.splice(index, 1);
    },
    displayVersionForSeller(version) {
      if (version &&  version !== "" && (this.isImpersonating || this.isSeller)) 
        return " (v" + version + ")";
      return "";
    }
  },
  beforeRouteEnter(to, _, next) {
    const title = store.getters['assessmentSetup/getAssessmentTitle'];
    to.meta.sidenav.title = title + ' Setup';
    next();
  },
  beforeRouteLeave(to, _, next) {
    const noConfirmationModalPaths = ['/assessments/summary', '/assessments/communication/activity']
    if (noConfirmationModalPaths.includes(to.path)) next();
    else {
      let list = [];
      for (let i = 0; i < this.mappedList.length; i++) {
        list.push(this.$refs[`email-${i}`][0].message);
      }

      if (this.checkChanges(list) || this.newMessages.length > 0)
        this.openModal({
          modal: 'exitAssessmentModal',
          props: {
            next: next,
            save: () => this.saveChanges(true),
            path: to.path,
          },
        });
      else next();
    }
  },
};
</script>
